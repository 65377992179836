.header {
  font-size: 30px;
  color: #00a14b;
  line-height: 27px;
  margin: 20px 0px 5px 0px;
}

.terms-and-condition {
  margin-bottom: 2rem;
}

.title {
  font-weight: 700;
  font-size: 18px;
  margin: 15px 0px 10px 0px;
}

.description {
  font-size: 16px;
  margin: 5px 0px;
}

.description p {
  margin-bottom: 0.5rem;
}

.button {
  display: flex;
  justify-content: center;
  gap: 1rem;
}