/* CSS Index */
/* 1. General */
/* 2. Variable */
/* 3. Navbar */

/* 1. General */

* {
  padding: 0;
  margin: 0;
  border: 0;
  box-sizing: border-box;
}

body {
  padding: 0;
  margin: 0;
  border: 0;
  font-family: "Barlow", sans-serif;
  overflow-x: hidden;
  background: #f6f6f6;
}

/* 2. Variable */
@media (min-width: 576px) {
  .position-sm-static {
    position: static !important;
  }

  .position-sm-relative {
    position: relative !important;
  }

  .position-sm-absolute {
    position: absolute !important;
  }

  .position-sm-fixed {
    position: fixed !important;
  }

  .position-sm-sticky {
    position: sticky !important;
  }
}

@media (min-width: 768px) {
  .position-md-static {
    position: static !important;
  }

  .position-md-relative {
    position: relative !important;
  }

  .position-md-absolute {
    position: absolute !important;
  }

  .position-md-fixed {
    position: fixed !important;
  }

  .position-md-sticky {
    position: sticky !important;
  }
}

@media (min-width: 992px) {
  .position-lg-static {
    position: static !important;
  }

  .position-lg-relative {
    position: relative !important;
  }

  .position-lg-absolute {
    position: absolute !important;
  }

  .position-lg-fixed {
    position: fixed !important;
  }

  .position-lg-sticky {
    position: sticky !important;
  }
}

@media (min-width: 1200px) {
  .position-xl-static {
    position: static !important;
  }

  .position-xl-relative {
    position: relative !important;
  }

  .position-xl-absolute {
    position: absolute !important;
  }

  .position-xl-fixed {
    position: fixed !important;
  }

  .position-xl-sticky {
    position: sticky !important;
  }
}

.f-bold {
  font-size: 500;
}

.bg-light {
  background: #ffffff !important;
}

.bg-gray {
  background-color: #f3f5f6;
  color: #4e5a6c;
  font-size: 12px;
  padding: 8px 12px;
  border: none;
  height: 40px;
  outline: none;
}

.bg-gr {
  background-color: #f3f5f6;
  color: #4e5a6c;
  font-size: 12px;
  border: none;
  height: 40px;
  outline: none;
}

.label-gr {
  color: #848f9f;
  font-size: 12px;
  font-weight: 500;
}

.label-green {
  color: #00a14b;
  font-size: 12px;
  font-weight: 300;
}

.label-red {
  color: #c5997c;
  font-size: 12px;
  font-weight: 300;
}

.new-radius {
  border-radius: 16px 16px 16px 16px !important;
}

.green-btn {
  background-color: #00a14b;
  font-size: 24px;
}

.nav-icon {
  max-width: 24px;
  width: 100%;
  height: auto;
}

.no-bdr {
  border-color: #00000000 !important;
}

/* select.form-select {
  -webkit-appearance: menulist;
  color: #9ca3af;
} */

.block-v {
  display: block !important;
}

.width-fc {
  width: fit-content !important;
}

/* 3. Navbar */
.navbar.navbar-expand-lg {
  z-index: 99;
  width: 100% !important;
  line-height: 2.5;
  word-spacing: 2;
  min-height: 103px;
  background-color: transparent;
  background: #ffffff;
}

.logo-img {
  max-width: 130px;
  width: 100%;
  height: auto;
}

.navbar-brand {
  margin-left: auto;
  margin-right: auto;
}

.navbar-toggler-icon:focus {
  outline: none;
  box-shadow: none;
}

.nav-link {
  font-size: 14px;
}

.nav-container {
  padding: 0;
  margin-left: 4px;
}

@media screen and (min-width: 576px) {
  .nav-container {
    margin-left: 20px;
  }
}

@media screen and (min-width: 1020px) {
  .nav-container {
    margin-left: 30px;
    margin-right: 30px;
  }
}

@media screen and (min-width: 1600px) {
  .nav-container {
    margin-left: 10%;
    margin-right: 10%;
  }
}

.nav-verify {
  font-size: 12px;
  background: #c5997c;
  text-decoration: none;
  color: #ffffff;
  padding: 4px;
  border-radius: 6px;
}

.nav-verified {
  font-size: 12px;
  background: #00a14b;
  text-decoration: none;
  color: #ffffff;
  padding: 4px;
  border-radius: 6px;
}

.nav-verify:hover {
  background: #c5997c;
  text-decoration: none;
  color: #ffffff;
}

.nav-user {
  color: #00a14b;
  text-decoration: none;
}

.nav-user i {
  font-size: 36px;
  color: #5f738c;
}

/* SideBar */
.s-bar {
  display: block;
}

@media screen and (min-width: 992px) {
  .collapse {
    display: block !important;
  }
}

.sidebar-text {
  font-size: 9px;
  color: #9ca3af;
}

.sidebar-item i {
  font-size: 24px;
  color: #5f738c;
}

.sticky-top {
  box-shadow: 0px 0px -10px 0px rgba(0, 0, 0, 0.25);
}

.nav-selected {
  background-color: #e5e5e520;
  border-left: 4px solid #00a14b !important;
}

.nav-selected i {
  color: #00a14b;
}

.nav-selected p {
  color: #272e35;
}

/* dashboard basic */
.dashboard-tiles {
  width: 100%;
}

/* dashboard tabs */

.dashboard-tabs {
  width: 120px;
  height: 56px;
  border-radius: 16px 16px 0px 0px !important;
  background-color: #ebeef0 !important;
  color: #5f738c;
  font-weight: 500;
}

.active {
  background-color: #fff;
}

@media screen and (min-width: 763px) {
  .dashboard-tabs {
    width: 320px;
  }
}

/* Inside Dashboard Tab */

.dashboard-tab-image {
  width: 100%;
  border-radius: 16px 16px 0 0;
}

.dashboard-heading div h1 {
  font-size: 24px;
  font-weight: 500;
}

@media screen and (min-width: 763px) {
  .dashboard-heading div h1 {
    font-size: 34px;
  }
}

.db-tab select {
  background-color: #f3f5f6;
  width: 180px;
  font-size: 12px;
  color: #353f50;
  height: 36px;
  border-radius: 4px;
  outline: none;
  padding: 2px;
}

/* toggle bar */

.db-panel {
  box-shadow: 1px 2px 16px rgba(0, 0, 0, 0.16);
  border-radius: 10px;
}

.db-panel .nav-pills .nav-item .nav-link {
  font-size: 12px;
  font-weight: 500;
  color: #000;
  width: 100%;
}

.db-panel .nav-pills {
  width: fit-content;
}

.db-panel .nav-pills .nav-item .nav-link.active {
  color: #ffffff;
  background-color: #4bc26b;
}

.db-panel .nav {
  flex-wrap: nowrap;
}

/* Dashboard Numbers */

.dasboard-numbers {
  background-color: #fff;
  box-shadow: 1px 2px 20px rgba(196, 196, 196, 0.4);
  border-radius: 16px;
}

.number-head {
  font-size: 28px;
  color: #000;
  font-weight: 500;
}

.number-text {
  font-size: 16px;
  color: #00a14b;
  font-weight: 500;
}

/* Tab Graph */

.db-graph-inner {
  background-color: #ffffff;
  border: 1px solid #e5e7eb;
  box-sizing: border-box;
  box-shadow: 1px 2px 20px rgba(0, 0, 0, 0.09);
  border-radius: 6px;
  box-sizing: content-box;
}

.graph-img {
  max-width: 500px;
  width: 100%;
  height: auto;
}

.graph-text {
  font-size: 18px;
  color: #909090;
}

/* last updated time area */

.last-time {
  font-size: 14px;
  color: #909090;
  height: fit-content;
}

.buy-btn {
  background-color: #00a14b;
  color: #ffffff;
  font-size: 24px;
}

/* Order History */

.orderhistory-head h1 {
  color: #00a14b;
  font-size: 30px;
}

.order-history {
  width: 100%;
  background: #ffffff;
  border-radius: 16px;
}

/* Order History Numbers */

.order-head {
  font-size: 26px;
  color: #000;
  font-weight: 500;
}

.order-text {
  font-size: 20px;
  color: #00a14b;
  font-weight: 500;
}

.order-head-pl {
  font-size: 26px;
  color: #00a14b;
  font-weight: 500;
}

.order-text-pl {
  font-size: 20px;
  color: #272e35;
  font-weight: 500;
}

/* Order history graph */

.orderhistory-graph {
  background: #ffffff;
  box-shadow: 1px 2px 20px rgba(196, 196, 196, 0.4);
  border-radius: 6px;
}

.orderhistory-graph-img {
  width: 100%;
}

/* tabular form */

.db-table {
  background-color: #ffffff;
  border: 1px solid #e5e7eb;
  box-sizing: border-box;
  box-shadow: 1px 2px 20px rgba(0, 0, 0, 0.09);
  border-radius: 6px;
}

.table-head h2 {
  color: #656565;
  font-weight: 600;
  font-size: 24px;
}

.table-main .table {
  overflow-x: scroll !important;
}

.table-ch {
  background-color: #f3f5f6;
}

.table-main {
  overflow: scroll;
}

.pl {
  color: #00a14b;
  font-weight: 500;
}

@media screen and (min-width: 762px) {
  .table-main::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .table-main {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
  }
}

tbody tr {
  border-bottom: 10px solid #fff !important;
  border-top: 10px solid #fff !important;
  background-color: #f3f5f6;
  font-size: 14px;
}

thead tr {
  border-bottom: 24px solid #fff !important;
  border-top: 24px solid #fff !important;
  background-color: #f3f5f6;
  font-size: 14px;
}

/* Table Form */

.tb-form {
  height: 40px;
  border: none;
  outline: none;
  background: #f3f5f6;
  padding-left: 12px;
}

.tb-form::placeholder {
  color: #aab7c6;
  font-size: 12px;
  margin: 4px;
}

.tb-label {
  color: #848f9f;
  font-size: 12px;
}

.tb-clear {
  border: 1px #00a14b solid;
}

.tb-submit {
  background-color: #00a14b;
}

/* Dashboard Form */

.nav-link.db-form-tab {
  color: #5f738c !important;
  border-radius: 16px 16px 0px 0px !important;
  height: 68px;
  background-color: #f3f5f6;
  font-size: 20px;
  font-weight: 500;
  border: none;
}

.nav-link.db-form-tab.active {
  color: #00a14b !important;
}

.dashboard-form-inner {
  background: #ffffff00;
  border-radius: 16px;
  filter: drop-shadow(1px 2px 20px rgba(0, 0, 0, 0.09));
}

.fa-circle {
  font-size: 24px;
  color: #c4c4c4;
}

/* yellow wsrning in form */

.yellow-warning {
  background-color: #ffee504d;
  border-radius: 10px;
}

.warning-text {
  color: #909090;
  font-size: 12px;
}

.warning-text-bold {
  color: #909090;
  font-size: 12px;
  font-weight: bold;
}

.warning-text-icon {
  color: #fbbc04;
}

/* Form Text */

.formt {
  color: #656565;
  font-size: 24px;
  font-weight: 400;
}

.form-discount {
  color: #656565;
  font-size: 20px;
  font-weight: 400;
}

/* KYC Head */

.kyc-head {
  font-size: 34px;
  font-weight: 500;
  height: fit-content;
}

/* kyc tab */
.kyc {
  background: #ffffff;
  border-radius: 16px;
  filter: drop-shadow(1px 2px 30px rgba(0, 0, 0, 0.09));
}

.kyc-pi {
  font-weight: 500;
  font-size: 20px;
  color: #00a14b;
}

.kyc-ca {
  font-weight: 500;
  font-size: 20px;
  color: #656565;
}

.kyc-ud {
  background: #ffffff;
  border: 1px solid #00a14b;
  border-radius: 8px;
  font-size: 16px !important;
  font-weight: 500 !important;
}

.kyc-plus {
  background: #00a14b;
  border-radius: 8px;
  color: #fff;
}

.kyc-minus {
  border: 1px solid #00a14b;
  border-radius: 8px;
  font-weight: 600;
  font-size: 14px;
}

.kyc-sb {
  height: 52px;
  background-color: #00a14b;
  color: #fff;
  font-size: 24px;
  font-weight: 600;
}

.kyc-sb-2 {
  height: 52px;
  background-color: #fad702;
  color: #fff;
  font-size: 24px;
  font-weight: 600;
}

/* Scan your address */

.ad-sc {
  border: 1px solid #00a14b !important;
  border-radius: 8px;
  height: 52px;
  font-size: 24px;
  background: transparent;
  color: #272e35;
}

/* Wallet address pop style */

/* Styles for dialog window */
#small-dialog {
  background: white;
  padding: 20px 30px;
  text-align: left;
  max-width: 640px;
  margin: 40px auto;
  position: relative;
  border-radius: 16px;
}

/**
 * Fade-zoom animation for first dialog
 */

/* start state */
.my-mfp-zoom-in .zoom-anim-dialog {
  opacity: 0;

  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;

  -webkit-transform: scale(0.8);
  -moz-transform: scale(0.8);
  -ms-transform: scale(0.8);
  -o-transform: scale(0.8);
  transform: scale(0.8);
}

/* animate in */
.my-mfp-zoom-in.mfp-ready .zoom-anim-dialog {
  opacity: 1;

  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}

/* animate out */
.my-mfp-zoom-in.mfp-removing .zoom-anim-dialog {
  -webkit-transform: scale(0.8);
  -moz-transform: scale(0.8);
  -ms-transform: scale(0.8);
  -o-transform: scale(0.8);
  transform: scale(0.8);

  opacity: 0;
}

/* Dark overlay, start state */
.my-mfp-zoom-in.mfp-bg {
  opacity: 0;
  -webkit-transition: opacity 0.3s ease-out;
  -moz-transition: opacity 0.3s ease-out;
  -o-transition: opacity 0.3s ease-out;
  transition: opacity 0.3s ease-out;
}

/* animate in */
.my-mfp-zoom-in.mfp-ready.mfp-bg {
  opacity: 0.8;
}

/* animate out */
.my-mfp-zoom-in.mfp-removing.mfp-bg {
  opacity: 0;
}

/**
 * Fade-move animation for second dialog
 */

/* at start */
.my-mfp-slide-bottom .zoom-anim-dialog {
  opacity: 0;
  -webkit-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;

  -webkit-transform: translateY(-20px) perspective(600px) rotateX(10deg);
  -moz-transform: translateY(-20px) perspective(600px) rotateX(10deg);
  -ms-transform: translateY(-20px) perspective(600px) rotateX(10deg);
  -o-transform: translateY(-20px) perspective(600px) rotateX(10deg);
  transform: translateY(-20px) perspective(600px) rotateX(10deg);
}

/* animate in */
.my-mfp-slide-bottom.mfp-ready .zoom-anim-dialog {
  opacity: 1;
  -webkit-transform: translateY(0) perspective(600px) rotateX(0);
  -moz-transform: translateY(0) perspective(600px) rotateX(0);
  -ms-transform: translateY(0) perspective(600px) rotateX(0);
  -o-transform: translateY(0) perspective(600px) rotateX(0);
  transform: translateY(0) perspective(600px) rotateX(0);
}

/* animate out */
.my-mfp-slide-bottom.mfp-removing .zoom-anim-dialog {
  opacity: 0;

  -webkit-transform: translateY(-10px) perspective(600px) rotateX(10deg);
  -moz-transform: translateY(-10px) perspective(600px) rotateX(10deg);
  -ms-transform: translateY(-10px) perspective(600px) rotateX(10deg);
  -o-transform: translateY(-10px) perspective(600px) rotateX(10deg);
  transform: translateY(-10px) perspective(600px) rotateX(10deg);
}

/* Dark overlay, start state */
.my-mfp-slide-bottom.mfp-bg {
  opacity: 0;

  -webkit-transition: opacity 0.3s ease-out;
  -moz-transition: opacity 0.3s ease-out;
  -o-transition: opacity 0.3s ease-out;
  transition: opacity 0.3s ease-out;
}

/* animate in */
.my-mfp-slide-bottom.mfp-ready.mfp-bg {
  opacity: 0.8;
}

/* animate out */
.my-mfp-slide-bottom.mfp-removing.mfp-bg {
  opacity: 0;
}

.pop-img {
  width: 100%;
  height: auto;
  max-width: 580px;
}

@media screen and (max-width: 1024px) {
  .number-head {
    font-size: 15px;
  }

  .order-head-pl {
    font-size: 15px;
  }

  .order-text-pl {
    font-size: 15px;
  }

  .order-head {
    font-size: 15px;
  }

  .order-text {
    font-size: 15px;
  }
}

@media screen and (max-width: 765px) {
  .number-head {
    font-size: 25px;
  }

  .order-head-pl {
    font-size: 25px;
  }

  .order-text-pl {
    font-size: 25px;
  }

  .order-head {
    font-size: 25px;
  }

  .order-text {
    font-size: 25px;
  }

  .wallet-address {
    margin-top: 150px !important;
  }
}

.header-margin {
  margin-top: 8rem !important;
}

#naturenft .dashboard-form-inner.rightCont #nav-tabContent .tab-pane {
  display: none;
}

#naturenft .dashboard-form-inner.rightCont #nav-tabContent .show-form {
  display: block;
}

#naturenft .dashboard-form-inner.rightCont #nav-tabContent #fiat-profile-right {
  opacity: 1;
}

.modal-map select {
  background-color: #f3f5f6;
  width: 180px;
  font-size: 12px;
  color: #353f50;
  height: 36px;
  border-radius: 4px;
  outline: none;
  padding: 2px;
}

.modal-map input {
  background: #ffffff;
  border: 1px solid #e3e6e9;
  border-radius: 8px;
  padding: 5px;
  width: 180px;
}

.modal-map textarea {
  background: #ffffff;
  border: 1px solid #e3e6e9;
  border-radius: 8px;
  padding: 5px;
  width: 100%;
}