.opacity-10 {
  opacity: 0.1;
}

.opacity-20 {
  opacity: 0.2;
}

.opacity-30 {
  opacity: 0.3;
}

.opacity-40 {
  opacity: 0.4;
}

.opacity-50 {
  opacity: 0.5;
}

.opacity-60 {
  opacity: 0.6;
}

.opacity-70 {
  opacity: 0.7;
}

.opacity-80 {
  opacity: 0.8;
}

.opacity-90 {
  opacity: 0.9;
}

.sunset-yellow {
  color: #fad702;
}

.w-20 {
  width: 20%;
}

.sunset-yellow-bg {
  background-color: #fad702;
}

.sunset-F3F5F6-bg {
  background-color: #f3f5f6;
}

.sunset-F2F2F2-bg {
  background-color: #f2f2f2;
}

.sunset-ffffff-bg {
  background-color: #fff;
}

.font-FBBC04 {
  color: #fbbc04;
}

.sunset-00A14B-bg {
  background-color: #00a14b;
}

.sunset-C4977C-bg {
  background-color: #c4977c;
}

.sunset-6CE3A4-bg {
  background-color: #6ce3a4;
}

.sunset-39E38833-bg {
  background: #39e38833;
}

.sunset-E5E5E5-bg {
  background: #e5e5e5;
}

.sunset-FFEE50-bg {
  background: #ffee50;
}

.sunset-FFEE50-bg {
  background: #ffee50;
}

.sunset-D8D8D8-bg {
  background-color: #d8d8d8;
}

.sunset-C5997C-bg {
  background-color: #c5997c;
}

.sunset-34A853-bg {
  background-color: #34a853;
}

.sunset-F89A34-bg {
  background-color: #f89a34;
}

.sunset-000000-bg {
  background-color: #000000;
}

.sunset-193B3C-bg {
  background-color: #193b3c;
}

.border-radidus-4 {
  border-radius: 4px;
}

.border-radidus-8 {
  border-radius: 8px;
}

.border-radidus-10 {
  border-radius: 10px;
}

.border-radidus-12 {
  border-radius: 12px;
}

.border-radidus-16 {
  border-radius: 16px;
}

.border-radidus-24 {
  border-radius: 24px;
}

.border-radidus-50 {
  border-radius: 50px;
}

.font-w-400 {
  font-weight: 400;
}

.font-w-500 {
  font-weight: 500;
}

.font-w-600 {
  font-weight: 600;
}

.font-w-700 {
  font-weight: 700;
}

.font-18 {
  font-size: 18px;
}

.font-15 {
  font-size: 15px;
}

.font-16 {
  font-size: 16px;
}

.font-36 {
  font-size: 36px;
}

.font-42 {
  font-size: 42px;
}

.font-38 {
  font-size: 38px;
}

.font-48 {
  font-size: 48px;
}

.font-30 {
  font-size: 30px !important;
}

.font-24 {
  font-size: 24px;
}

.font-20 {
  font-size: 20px;
}

.font-14 {
  font-size: 14px;
}

.font-12 {
  font-size: 12px;
}

.font-10 {
  font-size: 10px;
}

.font-8 {
  font-size: 8px;
}

.font-6E757C {
  color: #6e757c;
}

.font-DC4437 {
  color: #dc4437;
}

.font-272E35 {
  color: #272e35;
}

.font-919191 {
  color: #919191;
}

.font-848F9F {
  color: #848f9f;
}

.font-656565 {
  color: #656565;
}

.font-909090 {
  color: #909090;
}

.font-00A14B {
  color: #00a14b;
}

.font-C5997C {
  color: #c5997c;
}

.font-34A853 {
  color: #34a853;
}

.font-5F738C {
  color: #5f738c;
}

.font-ffffff,
.font-ffffff:hover {
  color: #fff;
  text-decoration: none;
}

.font-292930 {
  color: #292930;
}

.font-999FAE {
  color: #999fae;
}

.font-000000 {
  color: #000000;
}

.font-bg-ffc107 {
  background-color: #ffc107;
}

.text-green {
  color: #c5e41a;
}

.w-60 {
  width: 60%;
}

.w-40 {
  width: 40%;
}

.w-30 {
  width: 30%;
}

.w-45 {
  width: 45%;
}

.h-45 {
  height: 45%;
}

.w-fit-content {
  width: fit-content;
}

.border-left-2-C4C4C4 {
  border-left: 2px solid #c4c4c4;
}

.border-right-2-C4C4C4 {
  border-right: 2px solid #c4c4c4;
}

.sunset-input {
  border: 1px solid #e3e6e9;
}

.border-2-00A14B {
  border: 2px solid #00a14b;
}

.border-2-909090 {
  border: 2px solid #909090;
}

.sunset-input-active {
  border: none !important;
  box-shadow: 0 0 16px #dddddd !important;
}

.input-last-icons {
  top: -40px;
  left: -20px;
}

.my-token-tabs .nav button.active {
  color: #00a14b !important;
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
}

.carousel-indicators button {
  background-color: #d8d8d8 !important;
  height: 8px !important;
  width: 8px !important;
  border-radius: 50% !important;
}

.carousel-indicators button.active {
  background-color: #00db16 !important;
}

i:hover {
  cursor: pointer;
}

.news-headlines {
  margin: -160px 15px 0% 1%;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  background: rgba(0, 0, 0, 0.3);
}

.news-headlines div.card-body {
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  background: rgba(0, 0, 0, 0.3);
}

a {
  text-decoration: none;
  color: initial;
}

a:hover {
  text-decoration: none;
  color: initial;
}

.cursor-pointer:hover {
  cursor: pointer;
}

.notification {
  padding: 10px 20px;
  color: white;
  border-radius: 0px 0px 10px 10px;
  margin-top: -40px;
}

.page-item {
  margin-left: 20px !important;
}

.input-icon {
  background: initial;
  border-right: none;
  border: 1px solid #e3e6e9;
}

.input-icon+input {
  border-left: none;
}

.info-icon {
  height: 15px;
  margin-left: 2%;
}

.otp-all-input {
  height: 50px;
  width: 50px;
  margin: 0px 15px 20px 0px;
  border-radius: 4px;
  background-color: #f3f5f6;
  border: none;
  /* padding-left: 30%; */
  font-size: 16px !important;
  color: black;
}

.active {
  background: #ffffff !important;
  color: #fff !important;
}

.header-main .nav-item .nav-link {
  color: var(--tertiary);
  font-weight: 500;
}

.header-main .nav-item .nav-link.active {
  border-bottom: 2px solid var(--secondary) !important;
  color: var(--secondary) !important;
}

.modal-size {
  width: 50%;
  height: 50%;
}

.shadow-partner {
  box-shadow: -2px 6px 36px 5px rgba(0, 0, 0, 0.5);
}

.button-green {
  background-color: var(--primary);
  color: var(--white);
}

.button-brown {
  background-color: var(--secondary);
}

.modal-90w {
  max-width: 90% !important;
  height: 90% !important;
}

#set-dd {
  color: black;
  border: none;
  box-shadow: none;
  background-color: #f3f5f6;
  border-radius: 5px;
  width: 100%;
  height: 40px;
}

.dropdown-img {
  width: 30px;
}

.btn-warning {
  background-color: var(--primary);
  border-color: var(--primary);
  color: var(--white);
}

/* .btn-warning img {
  filter: brightness(0) invert(1);
} */

.bg-tertiary {
  background: var(--tertiary);
}

.position-unset {
  position: unset !important;
}

.summery-data-loader {
  position: relative;
  min-height: 11rem;
  background-color: #FFFFFF;
  border-radius: 15px;
  border: 1px solid transparent;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
}

.listing-data-loader {
  position: relative;
  min-height: 5rem;
  background-color: #F3F3F3;
  border-radius: 15px;
}

.graph-data-loader {
  position: relative;
  min-height: 10rem;
  background-color: #FFFFFF;
  border-radius: 15px;
  border: 1px solid transparent;
}

.order-history-loader {
  position: relative;
  min-height: 5rem;
  background-color: #fff;
  box-shadow: 1px 2px 20px rgba(196, 196, 196, 0.4);
  border-radius: 16px;
}

.monthly-graph-loader {
  position: relative;
  min-height: 10rem;
  background-color: #fff;
}

.summery-data-loader::after,
.listing-data-loader::after,
.graph-data-loader::after,
.order-history-loader::after,
.monthly-graph-loader::after {
  content: '';
  width: calc(100% - 30px);
  height: calc(100% - 30px);
  top: 15px;
  left: 15px;
  position: absolute;
  background-image:
    linear-gradient(100deg, transparent, rgba(255, 255, 255, 0.5) 50%, transparent 80%),
    radial-gradient(circle 28px at 28px 28px, #DDD 99%, transparent 0),
    linear-gradient(#DDD 24px, transparent 0),
    linear-gradient(#DDD 18px, transparent 0),
    linear-gradient(#DDD 66px, transparent 0);
  background-repeat: no-repeat;
  background-size: 75px 130px, 55px 56px, 160px 30px, 260px 20px, 100% 56px;
  background-position: 0% 0, 0 0, 70px 5px, 70px 38px, 0px 66px;
  animation: animloader124 1s linear infinite;
}

@keyframes animloader124 {
  0% {
    background-position: 0 0, 0 0, 0px 85px, 0px 110px
  }

  100% {
    background-position: 100% 0, 0 0, 0px 85px, 0px 110px
  }
}