.header-main {
  position: fixed;
  top: 0%;
  width: 100%;
  z-index: 1000;
  opacity: 1;
}

nav ul {
  padding: 1% 2% !important;
}

nav ul li {
  margin-left: 80px !important;
  align-self: center !important;
}

button.navbar-toggler {
  border: 1px solid #193b3c !important;
  border-radius: 4px !important;
  padding: 0.25rem 0.15rem !important;
}

.header-img {
  width: 147px;
  height: 64px;
}

.navbar-expand-md {
  flex-wrap: nowrap;
}

.connect-wallat-btn {
  border-radius: 0px;
  font-weight: 500;
  margin-right: 15px;
  font-size: 12px;
}

.connect-wallat-btn span.icon {
  display: inline-block;
}

.connect-wallat-btn span {
  display: none;
}

.connect-wallat-btn span.icon img {
  width: 16px;
  height: auto;
  filter: brightness(0) invert(1);
}

.connect-wallat-btn:hover {
  background-color: var(--secondary);
}

@media (min-width: 1200px) and (max-width: 1399px) {
  nav ul li {
    margin-left: 22px !important;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  nav ul li {
    margin-left: 15px !important;
  }

  nav ul li .nav-link {
    font-size: 14px !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  nav ul li {
    margin-left: 8px !important;
  }
}

@media (min-width: 992px) {
  .navbar-expand-md.rounded-corner {
    border-radius: 12px;
  }

  .connect-wallat-btn {
    border-radius: 0px 12px 12px 0px;
    margin-right: 0px;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .connect-wallat-btn span {
    display: inline-block;
  }

  .connect-wallat-btn span.icon {
    display: none;
  }
}

@media (min-width: 768px) {
  .header-main nav {
    padding: 0px;
    align-items: stretch;
  }

  .header-main nav>div:not(:last-of-type) {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  .navbar-expand-md {
    justify-content: space-between;
  }
}