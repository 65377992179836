.carousel-inner.vertical {
  height: 100%;
  /*Note: set specific height here if not, there will be some issues with IE browser*/
}

.carousel-inner.vertical>.carousel-item {
  -webkit-transition: 0.6s ease-in-out top !important;
  -o-transition: 0.6s ease-in-out top !important;
  transition: 0.6s ease-in-out top !important;
}

@media all and (transform-3d),
(-webkit-transform-3d) {
  .carousel-inner.vertical>.carousel-item {
    -webkit-transition: -webkit-transform 0.6s ease-in-out !important;
    -o-transition: -o-transform 0.6s ease-in-out !important;
    transition: transform 0.6s ease-in-out !important;
    -webkit-backface-visibility: hidden !important;
    backface-visibility: hidden !important;
    -webkit-perspective: 1000 !important;
    perspective: 1000 !important;
  }

  .carousel-inner.vertical>.carousel-item.carousel-item-next,
  .carousel-inner.vertical>.carousel-item.active.carousel-item-right {
    -webkit-transform: translate3d(0, 33.33%, 0) !important;
    transform: translate3d(0, 33.33%, 0) !important;
    top: 0 !important;
  }

  .carousel-inner.vertical>.carousel-item.carousel-item-prev,
  .carousel-inner.vertical>.carousel-item.active.carousel-item-left {
    -webkit-transform: translate3d(0, -33.33%, 0) !important;
    transform: translate3d(0, -33.33%, 0) !important;
    top: 0 !important;
  }

  .carousel-inner.vertical>.carousel-item.carousel-item-next.carousel-item-left,
  .carousel-inner.vertical>.carousel-item.carousel-item-prev.carousel-item-right,
  .carousel-inner.vertical>.carousel-item.active {
    -webkit-transform: translate3d(0, 0, 0) !important;
    transform: translate3d(0, 0, 0) !important;
    top: 0;
  }
}

.carousel-inner.vertical>.active {
  top: 0;
}

.carousel-inner.vertical>.carousel-item-next,
.carousel-inner.vertical>.carousel-item-prev {
  top: 0;
  height: 100%;
  width: auto;
}

.carousel-inner.vertical>.carousel-item-next {
  carousel-item-left: 0;
  top: 33.33%;
  carousel-item-right: 0;
}

.carousel-inner.vertical>.carousel-item-prev {
  carousel-item-left: 0;
  top: -33.33%;
  carousel-item-right: 0;
}

.carousel-inner.vertical>.carousel-item-next.carousel-item-left,
.carousel-inner.vertical>.carousel-item-prev.carousel-item-right {
  top: 0;
}

.carousel-inner.vertical>.active.carousel-item-left {
  carousel-item-left: 0;
  top: -33.33%;
  carousel-item-right: 0;
}

.carousel-inner.vertical>.active.carousel-item-right {
  carousel-item-left: 0;
  top: 33.33%;
  carousel-item-right: 0;
}

#carousel-pager .carousel-item-left.carousel-control-carousel-item-prev {
  bottom: initial;
  width: 100%;
}

#carousel-pager .carousel-item-right.carousel-control-carousel-item-next {
  top: initial;
  width: 100%;
}

.img-fluid1 {
  margin-bottom: 20px;
  border-radius: 5px;
  height: 65px !important;
}

.img-fluid2 {
  height: 449px !important;
  border-radius: 12px;
}

/* CSS Index */
/* 1. General */
/* 2. Variable */
/* 3. Navbar */

/* 1. General */

* {
  padding: 0;
  margin: 0;
  border: 0;
  box-sizing: border-box;
}

body {
  padding: 0;
  margin: 0;
  border: 0;
  font-family: "Barlow", sans-serif;
  overflow-x: hidden;
  background: #f6f6f6;
}

#map {
  width: 100%;
  height: 600px;
}

/* 2. Variable */
@media (min-width: 576px) {
  .position-sm-static {
    position: static !important;
  }

  .position-sm-relative {
    position: relative !important;
  }

  .position-sm-absolute {
    position: absolute !important;
  }

  .position-sm-fixed {
    position: fixed !important;
  }

  .position-sm-sticky {
    position: sticky !important;
  }
}

@media (min-width: 768px) {
  .position-md-static {
    position: static !important;
  }

  .position-md-relative {
    position: relative !important;
  }

  .position-md-absolute {
    position: absolute !important;
  }

  .position-md-fixed {
    position: fixed !important;
  }

  .position-md-sticky {
    position: sticky !important;
  }
}

@media (min-width: 992px) {
  .position-lg-static {
    position: static !important;
  }

  .position-lg-relative {
    position: relative !important;
  }

  .position-lg-absolute {
    position: absolute !important;
  }

  .position-lg-fixed {
    position: fixed !important;
  }

  .position-lg-sticky {
    position: sticky !important;
  }
}

@media (min-width: 1200px) {
  .position-xl-static {
    position: static !important;
  }

  .position-xl-relative {
    position: relative !important;
  }

  .position-xl-absolute {
    position: absolute !important;
  }

  .position-xl-fixed {
    position: fixed !important;
  }

  .position-xl-sticky {
    position: sticky !important;
  }
}

.f-bold {
  font-size: 500;
}

.bg-light {
  background: #ffffff !important;
}

.bg-DEB69C {
  background-color: #deb69c;
}

.bg-gray {
  background-color: #f3f5f6;
  color: #4e5a6c;
  font-size: 12px;
  padding: 8px 12px;
  border: none;
  height: 40px;
  outline: none;
}

.bg-gr {
  background-color: #f3f5f6;
  color: #4e5a6c;
  font-size: 12px;
  border: none;
  height: 40px;
  outline: none;
}

.label-gr {
  color: #848f9f;
  font-size: 12px;
  font-weight: 500;
}

.label-green {
  color: #00a14b;
  font-size: 12px;
  font-weight: 300;
}

.label-red {
  color: #c5997c;
  font-size: 12px;
  font-weight: 300;
}

.new-radius {
  border-radius: 16px 16px 16px 16px !important;
}

.green-btn {
  background-color: #00a14b;
  font-size: 24px;
}

.nav-icon {
  max-width: 24px;
  width: 100%;
  height: auto;
}

.no-bdr {
  border-color: #00000000 !important;
}

/* select.form-select {
  -webkit-appearance: menulist;
  color: #9ca3af;
} */

.block-v {
  display: block !important;
}

/* .width-fc{
      width: fit-content !important;
  } */
/* 3. Navbar */
.navbar.navbar-expand-lg {
  z-index: 99;
  width: 100% !important;
  line-height: 2.5;
  word-spacing: 2;
  min-height: 103px;
  background-color: transparent;
  background: #ffffff;
}

.logo-img {
  max-width: 130px;
  width: 100%;
  height: auto;
}

.navbar-brand {
  margin-left: auto;
  margin-right: auto;
}

.navbar-toggler-icon:focus {
  outline: none;
  box-shadow: none;
}

.nav-link {
  font-size: 14px;
}

.nav-container {
  padding: 0;
  margin-left: 4px;
}

@media screen and (min-width: 576px) {
  .nav-container {
    margin-left: 20px;
  }
}

@media screen and (min-width: 1020px) {
  .nav-container {
    margin-left: 30px;
    margin-right: 30px;
  }
}

@media screen and (min-width: 1600px) {
  .nav-container {
    margin-left: 10%;
    margin-right: 10%;
  }
}

.nav-verify {
  font-size: 12px;
  background: #c5997c;
  text-decoration: none;
  color: #ffffff;
  padding: 4px;
  border-radius: 6px;
}

.nav-verified {
  font-size: 12px;
  background: #00a14b;
  text-decoration: none;
  color: #ffffff;
  padding: 4px;
  border-radius: 6px;
}

.nav-verify:hover {
  background: #c5997c;
  text-decoration: none;
  color: #ffffff;
}

.nav-user {
  color: #00a14b;
  text-decoration: none;
}

.nav-user i {
  font-size: 36px;
  color: #5f738c;
}

/* SideBar */
.s-bar {
  display: block;
}

@media screen and (min-width: 992px) {
  .collapse {
    display: block !important;
  }
}

.sidebar-text {
  font-size: 9px;
  color: #9ca3af;
}

.sidebar-item i {
  font-size: 24px;
  color: #5f738c;
}

.sticky-top {
  box-shadow: 0px 0px -10px 0px rgba(0, 0, 0, 0.25);
}

.nav-selected {
  background-color: #e5e5e520;
  border-left: 4px solid #00a14b !important;
}

.nav-selected i {
  color: #00a14b;
}

.nav-selected p {
  color: #272e35;
}

/* dashboard basic */
.dashboard-tiles {
  width: 100%;
}

/* dashboard tabs */

.dashboard-tabs {
  width: 120px;
  height: 56px;
  border-radius: 16px 16px 0px 0px !important;
  background-color: #ebeef0 !important;
  color: #5f738c;
  font-weight: 500;
}

.active {
  background-color: #fff;
}

@media screen and (min-width: 763px) {
  .dashboard-tabs {
    width: 320px;
  }
}

/* Inside Dashboard Tab */

.dashboard-tab-image {
  width: 100%;
  border-radius: 16px 16px 0 0;
}

.dashboard-heading div h1 {
  font-size: 24px;
  font-weight: 500;
}

@media screen and (min-width: 763px) {
  .dashboard-heading div h1 {
    font-size: 34px;
  }
}

.db-tab select {
  background-color: #f3f5f6;
  width: 180px;
  font-size: 12px;
  color: #353f50;
  height: 36px;
  border-radius: 4px;
  outline: none;
  padding: 2px;
}

/* toggle bar */

.db-panel {
  box-shadow: 1px 2px 16px rgba(0, 0, 0, 0.16);
  border-radius: 10px;
}

.db-panel .nav-pills .nav-item .nav-link {
  font-size: 12px;
  font-weight: 500;
  color: #000;
  width: 100%;
}

.db-panel .nav-pills {
  width: fit-content;
}

.db-panel .nav-pills .nav-item .nav-link.active {
  color: #ffffff;
  background-color: #4bc26b;
}

.db-panel .nav {
  flex-wrap: nowrap;
}

/* Dashboard Numbers */

.dasboard-numbers {
  background-color: #fff;
  box-shadow: 1px 2px 20px rgba(196, 196, 196, 0.4);
  border-radius: 16px;
}

.number-head {
  font-size: 28px;
  color: #000;
  font-weight: 500;
}

.number-text {
  font-size: 16px;
  color: #00a14b;
  font-weight: 500;
}

/* Tab Graph */

.db-graph-inner {
  background-color: #ffffff;
  border: 1px solid #e5e7eb;
  box-sizing: border-box;
  box-shadow: 1px 2px 20px rgba(0, 0, 0, 0.09);
  border-radius: 6px;
  box-sizing: content-box;
}

.graph-img {
  max-width: 500px;
  width: 100%;
  height: auto;
}

.graph-text {
  font-size: 18px;
  color: #909090;
}

/* last updated time area */

.last-time {
  font-size: 14px;
  color: #909090;
  height: fit-content;
}

.buy-btn {
  background-color: #00a14b;
  color: #ffffff;
  font-size: 24px;
}

/* Order History */

.orderhistory-head h1 {
  color: #00a14b;
  font-size: 30px;
}

.order-history {
  width: 100%;
  background: #ffffff;
  border-radius: 16px;
}

/* Order History Numbers */

.order-head {
  font-size: 26px;
  color: #000;
  font-weight: 500;
}

.order-text {
  font-size: 20px;
  color: #00a14b;
  font-weight: 500;
}

.order-head-pl {
  font-size: 26px;
  color: #00a14b;
  font-weight: 500;
}

.order-text-pl {
  font-size: 20px;
  color: #272e35;
  font-weight: 500;
}

/* Order history graph */

.orderhistory-graph {
  background: #ffffff;
  box-shadow: 1px 2px 20px rgba(196, 196, 196, 0.4);
  border-radius: 6px;
}

.orderhistory-graph-img {
  width: 100%;
}

/* tabular form */

.db-table {
  background-color: #ffffff;
  border: 1px solid #e5e7eb;
  box-sizing: border-box;
  box-shadow: 1px 2px 20px rgba(0, 0, 0, 0.09);
  border-radius: 6px;
}

.table-head h2 {
  color: #656565;
  font-weight: 600;
  font-size: 24px;
}

.table-main .table {
  overflow-x: scroll !important;
}

.table-ch {
  background-color: #f3f5f6;
}

.table-main {
  overflow: scroll;
}

.pl {
  color: #00a14b;
  font-weight: 500;
}

@media screen and (min-width: 762px) {
  .table-main::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .table-main {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
  }
}

tbody tr {
  border-bottom: 10px solid #fff !important;
  border-top: 10px solid #fff !important;
  background-color: #f3f5f6;
  font-size: 14px;
}

thead tr {
  border-bottom: 24px solid #fff !important;
  border-top: 24px solid #fff !important;
  background-color: #f3f5f6;
  font-size: 14px;
}

/* Table Form */

.tb-form {
  height: 40px;
  border: none;
  outline: none;
  background: #f3f5f6;
  padding-left: 12px;
}

.tb-form::placeholder {
  color: #aab7c6;
  font-size: 12px;
  margin: 4px;
}

.tb-label {
  color: #848f9f;
  font-size: 12px;
}

.tb-clear {
  border: 1px #00a14b solid;
}

.tb-submit {
  background-color: #00a14b;
  color: #ffffff;
}

/* Dashboard Form */

.nav-link.db-form-tab {
  color: #5f738c !important;
  border-radius: 16px 16px 0px 0px !important;
  height: 68px;
  background-color: #f3f5f6;
  font-size: 20px;
  font-weight: 500;
  border: none;
}

.nav-link.db-form-tab.active {
  color: #00a14b !important;
}

.dashboard-form-inner {
  background: #ffffff00;
  border-radius: 16px;
  filter: drop-shadow(1px 2px 20px rgba(0, 0, 0, 0.09));
}

.fa-circle {
  font-size: 24px;
  color: #c4c4c4;
}

/* yellow wsrning in form */

.yellow-warning {
  background-color: #ffee504d;
  border-radius: 10px;
}

.warning-text {
  color: #909090;
  font-size: 12px;
}

.warning-text-icon {
  color: #fbbc04;
}

/* Form Text */

.formt {
  color: #656565;
  font-size: 24px;
  font-weight: 400;
}

/* KYC Head */

.kyc-head {
  font-size: 34px;
  font-weight: 500;
  height: fit-content;
}

/* kyc tab */
.kyc {
  background: #ffffff;
  border-radius: 16px;
  filter: drop-shadow(1px 2px 30px rgba(0, 0, 0, 0.09));
}

.kyc-pi {
  font-weight: 500;
  font-size: 20px;
  color: #00a14b;
}

.kyc-ca {
  font-weight: 500;
  font-size: 20px;
  color: #656565;
}

.kyc-ud {
  background: #ffffff;
  border: 1px solid #00a14b;
  border-radius: 8px;
  font-size: 16px !important;
  font-weight: 500 !important;
}

.kyc-plus {
  background: #00a14b;
  border-radius: 8px;
  color: #fff;
}

.kyc-minus {
  border: 1px solid #00a14b;
  border-radius: 8px;
  font-weight: 600;
  font-size: 14px;
}

.kyc-sb {
  height: 52px;
  background-color: #00a14b;
  color: #fff;
  font-size: 24px;
  font-weight: 600;
}

/* Scan your address */

.ad-sc {
  border: 1px solid #00a14b !important;
  border-radius: 8px;
  height: 52px;
  font-size: 24px;
  background: transparent;
  color: #272e35;
}

/* Wallet address pop style */

/* Styles for dialog window */
#small-dialog {
  background: white;
  padding: 20px 30px;
  text-align: left;
  max-width: 640px;
  margin: 40px auto;
  position: relative;
  border-radius: 16px;
}

/**
   * Fade-zoom animation for first dialog
   */

/* start state */
.my-mfp-zoom-in .zoom-anim-dialog {
  opacity: 0;

  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;

  -webkit-transform: scale(0.8);
  -moz-transform: scale(0.8);
  -ms-transform: scale(0.8);
  -o-transform: scale(0.8);
  transform: scale(0.8);
}

/* animate in */
.my-mfp-zoom-in.mfp-ready .zoom-anim-dialog {
  opacity: 1;

  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}

/* animate out */
.my-mfp-zoom-in.mfp-removing .zoom-anim-dialog {
  -webkit-transform: scale(0.8);
  -moz-transform: scale(0.8);
  -ms-transform: scale(0.8);
  -o-transform: scale(0.8);
  transform: scale(0.8);

  opacity: 0;
}

/* Dark overlay, start state */
.my-mfp-zoom-in.mfp-bg {
  opacity: 0;
  -webkit-transition: opacity 0.3s ease-out;
  -moz-transition: opacity 0.3s ease-out;
  -o-transition: opacity 0.3s ease-out;
  transition: opacity 0.3s ease-out;
}

/* animate in */
.my-mfp-zoom-in.mfp-ready.mfp-bg {
  opacity: 0.8;
}

/* animate out */
.my-mfp-zoom-in.mfp-removing.mfp-bg {
  opacity: 0;
}

/**
   * Fade-move animation for second dialog
   */

/* at start */
.my-mfp-slide-bottom .zoom-anim-dialog {
  opacity: 0;
  -webkit-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;

  -webkit-transform: translateY(-20px) perspective(600px) rotateX(10deg);
  -moz-transform: translateY(-20px) perspective(600px) rotateX(10deg);
  -ms-transform: translateY(-20px) perspective(600px) rotateX(10deg);
  -o-transform: translateY(-20px) perspective(600px) rotateX(10deg);
  transform: translateY(-20px) perspective(600px) rotateX(10deg);
}

/* animate in */
.my-mfp-slide-bottom.mfp-ready .zoom-anim-dialog {
  opacity: 1;
  -webkit-transform: translateY(0) perspective(600px) rotateX(0);
  -moz-transform: translateY(0) perspective(600px) rotateX(0);
  -ms-transform: translateY(0) perspective(600px) rotateX(0);
  -o-transform: translateY(0) perspective(600px) rotateX(0);
  transform: translateY(0) perspective(600px) rotateX(0);
}

/* animate out */
.my-mfp-slide-bottom.mfp-removing .zoom-anim-dialog {
  opacity: 0;

  -webkit-transform: translateY(-10px) perspective(600px) rotateX(10deg);
  -moz-transform: translateY(-10px) perspective(600px) rotateX(10deg);
  -ms-transform: translateY(-10px) perspective(600px) rotateX(10deg);
  -o-transform: translateY(-10px) perspective(600px) rotateX(10deg);
  transform: translateY(-10px) perspective(600px) rotateX(10deg);
}

/* Dark overlay, start state */
.my-mfp-slide-bottom.mfp-bg {
  opacity: 0;

  -webkit-transition: opacity 0.3s ease-out;
  -moz-transition: opacity 0.3s ease-out;
  -o-transition: opacity 0.3s ease-out;
  transition: opacity 0.3s ease-out;
}

/* animate in */
.my-mfp-slide-bottom.mfp-ready.mfp-bg {
  opacity: 0.8;
}

/* animate out */
.my-mfp-slide-bottom.mfp-removing.mfp-bg {
  opacity: 0;
}

.pop-img {
  width: 100%;
  height: auto;
  max-width: 580px;
}

/* New Dropdown HTML-CSS */

.dropbtn {
  background: #f3f5f6;
  border-radius: 4px;
  color: #353f50;
  padding: 12px;
  font-size: 12px;
  border: none;
  cursor: pointer;
  height: 40px;
  vertical-align: middle;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  font-size: 12px;
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 200px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 12px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #f1f1f1;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.d-icon {
  width: 100%;
  max-width: 20px;
  height: auto;
}

/* Tab Icon */

.tab-icon {
  width: 100%;
  max-width: 15px;
  height: auto;
}

/* Stop Hover action */

.tb-submit:hover,
.buy-btn:hover {
  /* .kyc-sb:hover, */
  /* .ad-sc:hover { */
  /* background-color: #35503f; */
}

/* About */
/* 
  .det-about{

  } */

.det-h6 {
  font-weight: 600;
  font-size: 24px;
  color: #34a853;
}

.det-h3 {
  font-weight: 500;
  font-size: 37px;
  color: #000;
}

.det-p {
  font-size: 16px;
  color: #656565;
}

.det-pr {
  color: #000;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
}

.det-img-inner {
  width: 100%;
  max-width: 115px;
  height: auto;
}

.det-vid-img {
  width: 100%;
  max-width: 620px;
  height: auto;
}

/* Added for Popup */

/* .modal-dialog {
  max-width: 100%;
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0 8px;
  box-sizing: border-box;
} */

.modal-content {
  cursor: auto;
}

.white-popup-block {
  background-color: #fff;
  box-shadow: 1px 2px 20px rgba(145, 145, 145, 0.3);
  border-radius: 8px;
  width: 100%;
}

.white-popup-block h1 {
  background-color: #fff;
  font-weight: 500;
  font-size: 37px;
}

.modal-img {
  width: 100%;
  max-width: 1269px;
}

.modal-box {
  top: 40px;
  right: 20px;
  background-color: #fff;
  width: 400px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}

/* Google Map Container */
.map-container {
  height: 500px;
  width: 100%;
}

.hide-form {
  display: none;
}

.show-form {
  display: block;
}

.wallet-form-background {
  background-color: white;
  border-radius: 0px 20px 20px 20px;
  padding: 50px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 15px;
}

.wallet-form {
  border: 1px solid #e5e7eb;
  box-shadow: 1px 2px 20px rgba(0, 0, 0, 0.09);
  border-radius: 6px;
}

.wallet-form input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.wallet-form input[type="number"] {
  -moz-appearance: textfield;
}

.notstated-notification {
  background-color: #ff771b;
  border-radius: 100px;
  height: 18px;
  width: 18px;
}

.pending-notification {
  background-color: #e1be5a;
  border-radius: 100px;
  height: 18px;
  width: 18px;
}

.completed-notification {
  background-color: #00a14b;
  border-radius: 100px;
  height: 18px;
  width: 18px;
}

.kyc-img {
  height: 150px;
  margin-top: -100px;
}

.display-block {
  display: flex;
}

@media (max-width: 650px) {
  .display-block {
    display: block;
  }
}

@media (max-width: 1000px) {
  .profile-margin {
    margin-top: 100px;
  }
}

.center-img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

.wallet-connect-button {
  border: 2px solid #fad702;
  border-radius: 8px;
  color: black;
  background-color: transparent;
  height: 100px;
  font-size: 20px;
}

.qrCode-input input {
  border: 1px solid black;
  border-radius: 8px;
  width: 56px !important;
  height: 56px;
  font-size: 12px;
  color: #000;
  font-weight: 400;
}

td.pending {
  color: #ffc700 !important;
}

td.payment-received {
  color: #127de0 !important;
}

td.token-initiated {
  color: #c55e1b !important;
}

td.payment-mismatch {
  color: #fc1321eb !important;
}

td.done {
  color: #00a14b !important;
}

td.in-process {
  color: #6c15b0 !important;
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}