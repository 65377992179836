@import url(https://fonts.googleapis.com/css?family=Montserrat:400,700);
@import url(https://fonts.googleapis.com/css?family=Lora);
[class*=" imghvr-"],
[class^="imghvr-"] {
  font-family: Montserrat, sans-serif;
  position: relative;
  display: inline-block;
  margin: 0;
  max-width: 100%;
  background-color: rgba(230, 230, 230, 0.507);
  color: #666;
  overflow: hidden;
  -moz-osx-font-smoothing: grayscale;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}
[class*=" imghvr-"] img,
[class^="imghvr-"] img {
  vertical-align: top;
  max-width: 100%;
}
[class*=" imghvr-"] figcaption,
[class^="imghvr-"] figcaption {
  background-color: #f51a1a;
  padding: 20px;
  position: absolute;
  /* top: 15px; */
  bottom: 35px;
  left: 15px;
  right: 15px;
  border: 1px solid rgba(0, 0, 0, 0.1);
}
[class*=" imghvr-"] h3,
[class*=" imghvr-"] p,
[class^="imghvr-"] h3,
[class^="imghvr-"] p {
  margin: 0;
  padding: 0;
}
[class*=" imghvr-"] h3,
[class^="imghvr-"] h3 {
  font-weight: 700;
  /* color: #333; */
  margin-bottom: 8px;
}
[class*=" imghvr-"] p,
[class^="imghvr-"] p,[class*=" imghvr-"] a,
[class^="imghvr-"] a, [class*=" imghvr-"] a:hover,
[class^="imghvr-"] a:hover {
  font-family: Lora, sans-serif;
  font-size: 0.9em;
  color: white;
}
/* [class*=" imghvr-"] a,
[class^="imghvr-"] a {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
} */
[class*=" imghvr-"],
[class*=" imghvr-"] *,
[class*=" imghvr-"] :after,
[class*=" imghvr-"] :before,
[class*=" imghvr-"]:after,
[class*=" imghvr-"]:before,
[class^="imghvr-"],
[class^="imghvr-"] *,
[class^="imghvr-"] :after,
[class^="imghvr-"] :before,
[class^="imghvr-"]:after,
[class^="imghvr-"]:before {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 0.35s ease;
  transition: all 0.35s ease;
}
.imghvr-fade figcaption,
.imghvr-fade:hover img {
  opacity: 0;
}
.imghvr-fade:hover figcaption {
  opacity: 1;
}
[class*=" imghvr-push-"]:hover figcaption,
[class*=" imghvr-slide-"]:hover figcaption,
[class^="imghvr-push-"]:hover figcaption,
[class^="imghvr-slide-"]:hover figcaption {
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
}
.imghvr-push-up figcaption {
  -webkit-transform: translateY(120%);
  transform: translateY(120%);
}
.imghvr-push-up:hover img {
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
}
.imghvr-push-down figcaption {
  -webkit-transform: translateY(-120%);
  transform: translateY(-120%);
}
.imghvr-push-down:hover img {
  -webkit-transform: translateY(100%);
  transform: translateY(100%);
}
.imghvr-push-left figcaption {
  -webkit-transform: translateX(120%);
  transform: translateX(120%);
}
.imghvr-push-left:hover img {
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}
.imghvr-push-right figcaption {
  -webkit-transform: translateX(-120%);
  transform: translateX(-120%);
}
.imghvr-push-right:hover img {
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}
.imghvr-slide-up figcaption {
  -webkit-transform: translateY(120%);
  transform: translateY(120%);
}
.imghvr-slide-down figcaption {
  -webkit-transform: translateY(-120%);
  transform: translateY(-120%);
}
.imghvr-slide-left figcaption {
  -webkit-transform: translateX(120%);
  transform: translateX(120%);
}
.imghvr-slide-right figcaption {
  -webkit-transform: translateX(-120%);
  transform: translateX(-120%);
}
[class*=" imghvr-reveal-"]:before,
[class^="imghvr-reveal-"]:before {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  content: "";
  background-color: inherit;
}
[class*=" imghvr-reveal-"] figcaption,
[class^="imghvr-reveal-"] figcaption {
  opacity: 0;
}
[class*=" imghvr-reveal-"]:hover:before,
[class^="imghvr-reveal-"]:hover:before {
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
}
[class*=" imghvr-reveal-"]:hover figcaption,
[class^="imghvr-reveal-"]:hover figcaption {
  opacity: 1;
  -webkit-transition-delay: 0.2s;
  transition-delay: 0.2s;
}
.imghvr-reveal-up:before {
  -webkit-transform: translateY(100%);
  transform: translateY(100%);
}
.imghvr-reveal-down:before {
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
}
.imghvr-reveal-left:before {
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}
.imghvr-reveal-right:before {
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}
[class*=" imghvr-hinge-"],
[class^="imghvr-hinge-"] {
  -webkit-perspective: 50em;
  perspective: 50em;
}
[class*=" imghvr-hinge-"] figcaption,
[class^="imghvr-hinge-"] figcaption {
  opacity: 0;
  z-index: 1;
}
[class*=" imghvr-hinge-"]:hover img,
[class^="imghvr-hinge-"]:hover img {
  opacity: 0;
}
[class*=" imghvr-hinge-"]:hover figcaption,
[class^="imghvr-hinge-"]:hover figcaption {
  opacity: 1;
  -webkit-transition-delay: 0.2s;
  transition-delay: 0.2s;
}
.imghvr-hinge-up img {
  -webkit-transform-origin: 50% 0;
  -ms-transform-origin: 50% 0;
  transform-origin: 50% 0;
}
.imghvr-hinge-up figcaption {
  -webkit-transform: rotateX(90deg);
  transform: rotateX(90deg);
  -webkit-transform-origin: 50% 100%;
  -ms-transform-origin: 50% 100%;
  transform-origin: 50% 100%;
}
.imghvr-hinge-up:hover img {
  -webkit-transform: rotateX(-90deg);
  transform: rotateX(-90deg);
}
.imghvr-hinge-up:hover figcaption {
  -webkit-transform: rotateX(0);
  transform: rotateX(0);
}
.imghvr-hinge-down img {
  -webkit-transform-origin: 50% 100%;
  -ms-transform-origin: 50% 100%;
  transform-origin: 50% 100%;
}
.imghvr-hinge-down figcaption {
  -webkit-transform: rotateX(-90deg);
  transform: rotateX(-90deg);
  -webkit-transform-origin: 50% -50%;
  -ms-transform-origin: 50% -50%;
  transform-origin: 50% -50%;
}
.imghvr-hinge-down:hover img {
  -webkit-transform: rotateX(90deg);
  transform: rotateX(90deg);
  opacity: 0;
}
.imghvr-hinge-down:hover figcaption {
  -webkit-transform: rotateX(0);
  transform: rotateX(0);
}
.imghvr-hinge-left img {
  -webkit-transform-origin: 0 50%;
  -ms-transform-origin: 0 50%;
  transform-origin: 0 50%;
}
.imghvr-hinge-left figcaption {
  -webkit-transform: rotateY(-90deg);
  transform: rotateY(-90deg);
  -webkit-transform-origin: 100% 50%;
  -ms-transform-origin: 100% 50%;
  transform-origin: 100% 50%;
}
.imghvr-hinge-left:hover img {
  -webkit-transform: rotateY(90deg);
  transform: rotateY(90deg);
}
.imghvr-hinge-left:hover figcaption {
  -webkit-transform: rotateY(0);
  transform: rotateY(0);
}
.imghvr-hinge-right img {
  -webkit-transform-origin: 100% 50%;
  -ms-transform-origin: 100% 50%;
  transform-origin: 100% 50%;
}
.imghvr-hinge-right figcaption {
  -webkit-transform: rotateY(90deg);
  transform: rotateY(90deg);
  -webkit-transform-origin: 0 50%;
  -ms-transform-origin: 0 50%;
  transform-origin: 0 50%;
}
.imghvr-hinge-right:hover img {
  -webkit-transform: rotateY(-90deg);
  transform: rotateY(-90deg);
}
.imghvr-hinge-right:hover figcaption {
  -webkit-transform: rotateY(0);
  transform: rotateY(0);
}
[class*=" imghvr-flip-"],
[class^="imghvr-flip-"] {
  -webkit-perspective: 50em;
  perspective: 50em;
}
[class*=" imghvr-flip-"] img,
[class^="imghvr-flip-"] img {
  backface-visibility: hidden;
}
[class*=" imghvr-flip-"] figcaption,
[class^="imghvr-flip-"] figcaption {
  opacity: 0;
}
[class*=" imghvr-flip-"]:hover figcaption,
[class^="imghvr-flip-"]:hover figcaption {
  opacity: 1;
  -webkit-transition-delay: 0.15s;
  transition-delay: 0.15s;
}
.imghvr-flip-horiz figcaption {
  -webkit-transform: rotateX(90deg);
  transform: rotateX(90deg);
  -webkit-transform-origin: 0 50%;
  -ms-transform-origin: 0 50%;
  transform-origin: 0 50%;
}
.imghvr-flip-horiz:hover figcaption {
  -webkit-transform: rotateX(0);
  transform: rotateX(0);
}
.imghvr-flip-vert figcaption {
  -webkit-transform: rotateY(90deg);
  transform: rotateY(90deg);
  -webkit-transform-origin: 50% 0;
  -ms-transform-origin: 50% 0;
  transform-origin: 50% 0;
}
.imghvr-flip-vert:hover figcaption {
  -webkit-transform: rotateY(0);
  transform: rotateY(0);
}
.imghvr-flip-diag-1 figcaption {
  -webkit-transform: rotate3d(1, -1, 0, 100deg);
  transform: rotate3d(1, -1, 0, 100deg);
}
.imghvr-flip-diag-1:hover figcaption {
  -webkit-transform: rotate3d(0, 0, 0, 0deg);
  transform: rotate3d(0, 0, 0, 0deg);
}
.imghvr-flip-diag-2 figcaption {
  -webkit-transform: rotate3d(1, 1, 0, 100deg);
  transform: rotate3d(1, 1, 0, 100deg);
}
.imghvr-flip-diag-2:hover figcaption {
  -webkit-transform: rotate3d(0, 0, 0, 0deg);
  transform: rotate3d(0, 0, 0, 0deg);
}
[class*=" imghvr-shutter-out-"]:before,
[class^="imghvr-shutter-out-"]:before {
  background: inherit;
  position: absolute;
  content: "";
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
}
[class*=" imghvr-shutter-out-"] figcaption,
[class^="imghvr-shutter-out-"] figcaption {
  opacity: 0;
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}
[class*=" imghvr-shutter-out-"]:hover:before,
[class^="imghvr-shutter-out-"]:hover:before {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}
[class*=" imghvr-shutter-out-"]:hover figcaption,
[class^="imghvr-shutter-out-"]:hover figcaption {
  opacity: 1;
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
}
.imghvr-shutter-out-horiz:before {
  left: 50%;
  right: 50%;
  top: 0;
  bottom: 0;
}
.imghvr-shutter-out-horiz:hover:before {
  left: 0;
  right: 0;
}
.imghvr-shutter-out-vert:before {
  top: 50%;
  bottom: 50%;
  left: 0;
  right: 0;
}
.imghvr-shutter-out-vert:hover:before {
  top: 0;
  bottom: 0;
}
.imghvr-shutter-out-diag-1:before {
  top: 50%;
  bottom: 50%;
  left: -35%;
  right: -35%;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
.imghvr-shutter-out-diag-1:hover:before {
  top: -35%;
  bottom: -35%;
}
.imghvr-shutter-out-diag-2:before {
  top: 50%;
  bottom: 50%;
  left: -35%;
  right: -35%;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.imghvr-shutter-out-diag-2:hover:before {
  top: -35%;
  bottom: -35%;
}
[class*=" imghvr-shutter-in-"]:after,
[class*=" imghvr-shutter-in-"]:before,
[class^="imghvr-shutter-in-"]:after,
[class^="imghvr-shutter-in-"]:before {
  background: inherit;
  position: absolute;
  content: "";
}
[class*=" imghvr-shutter-in-"]:after,
[class^="imghvr-shutter-in-"]:after {
  top: 0;
  left: 0;
}
[class*=" imghvr-shutter-in-"]:before,
[class^="imghvr-shutter-in-"]:before {
  right: 0;
  bottom: 0;
}
[class*=" imghvr-shutter-in-"] figcaption,
[class^="imghvr-shutter-in-"] figcaption {
  opacity: 0;
  z-index: 1;
}
[class*=" imghvr-shutter-in-"]:hover figcaption,
[class^="imghvr-shutter-in-"]:hover figcaption {
  opacity: 1;
  -webkit-transition-delay: 0.2s;
  transition-delay: 0.2s;
}
.imghvr-shutter-in-horiz:after,
.imghvr-shutter-in-horiz:before {
  width: 0;
  height: 100%;
}
.imghvr-shutter-in-horiz:hover:after,
.imghvr-shutter-in-horiz:hover:before {
  width: 50%;
}
.imghvr-shutter-in-vert:after,
.imghvr-shutter-in-vert:before {
  height: 0;
  width: 100%;
}
.imghvr-shutter-in-vert:hover:after,
.imghvr-shutter-in-vert:hover:before {
  height: 50%;
}
.imghvr-shutter-in-out-horiz:after,
.imghvr-shutter-in-out-horiz:before {
  width: 0;
  height: 100%;
}
.imghvr-shutter-in-out-horiz:hover:after,
.imghvr-shutter-in-out-horiz:hover:before {
  width: 100%;
}
.imghvr-shutter-in-out-vert:after,
.imghvr-shutter-in-out-vert:before {
  height: 0;
  width: 100%;
}
.imghvr-shutter-in-out-vert:hover:after,
.imghvr-shutter-in-out-vert:hover:before {
  height: 100%;
}
.imghvr-shutter-in-out-diag-1:after,
.imghvr-shutter-in-out-diag-1:before {
  width: 200%;
  height: 200%;
  -webkit-transition: all 0.6s ease;
  transition: all 0.6s ease;
}
.imghvr-shutter-in-out-diag-1:after {
  -webkit-transform: skew(-45deg) translateX(-150%);
  transform: skew(-45deg) translateX(-150%);
}
.imghvr-shutter-in-out-diag-1:before {
  -webkit-transform: skew(-45deg) translateX(150%);
  transform: skew(-45deg) translateX(150%);
}
.imghvr-shutter-in-out-diag-1:hover:after {
  -webkit-transform: skew(-45deg) translateX(-50%);
  transform: skew(-45deg) translateX(-50%);
}
.imghvr-shutter-in-out-diag-1:hover:before {
  -webkit-transform: skew(-45deg) translateX(50%);
  transform: skew(-45deg) translateX(50%);
}
.imghvr-shutter-in-out-diag-2:after,
.imghvr-shutter-in-out-diag-2:before {
  width: 200%;
  height: 200%;
  -webkit-transition: all 0.6s ease;
  transition: all 0.6s ease;
}
.imghvr-shutter-in-out-diag-2:after {
  -webkit-transform: skew(45deg) translateX(-100%);
  transform: skew(45deg) translateX(-100%);
}
.imghvr-shutter-in-out-diag-2:before {
  -webkit-transform: skew(45deg) translateX(100%);
  transform: skew(45deg) translateX(100%);
}
.imghvr-shutter-in-out-diag-2:hover:after,
.imghvr-shutter-in-out-diag-2:hover:before {
  -webkit-transform: skew(45deg) translateX(0);
  transform: skew(45deg) translateX(0);
}
[class*=" imghvr-fold"],
[class^="imghvr-fold"] {
  -webkit-perspective: 50em;
  perspective: 50em;
}
[class*=" imghvr-fold"] img,
[class^="imghvr-fold"] img {
  -webkit-transform-origin: 50% 0;
  -ms-transform-origin: 50% 0;
  transform-origin: 50% 0;
}
[class*=" imghvr-fold"] figcaption,
[class^="imghvr-fold"] figcaption {
  z-index: 1;
  opacity: 0;
}
[class*=" imghvr-fold"]:hover img,
[class^="imghvr-fold"]:hover img {
  opacity: 0;
  -webkit-transition-delay: 0;
  transition-delay: 0;
}
[class*=" imghvr-fold"]:hover figcaption,
[class^="imghvr-fold"]:hover figcaption {
  -webkit-transform: rotateX(0) translate3d(0, 0, 0) scale(1);
  transform: rotateX(0) translate3d(0, 0, 0) scale(1);
  opacity: 1;
  -webkit-transition-delay: 0.2s;
  transition-delay: 0.2s;
}
.imghvr-fold-up img {
  -webkit-transform-origin: 50% 0;
  -ms-transform-origin: 50% 0;
  transform-origin: 50% 0;
}
.imghvr-fold-up figcaption {
  -webkit-transform: rotateX(-90deg) translate3d(0, -50%, 0) scale(0.6);
  transform: rotateX(-90deg) translate3d(0, -50%, 0) scale(0.6);
  -webkit-transform-origin: 50% 100%;
  -ms-transform-origin: 50% 100%;
  transform-origin: 50% 100%;
}
.imghvr-fold-up:hover img {
  -webkit-transform: rotateX(90deg) scale(0.6) translateY(50%);
  transform: rotateX(90deg) scale(0.6) translateY(50%);
}
.imghvr-fold-down img {
  -webkit-transform-origin: 50% 100%;
  -ms-transform-origin: 50% 100%;
  transform-origin: 50% 100%;
}
.imghvr-fold-down figcaption {
  -webkit-transform: rotateX(90deg) translate3d(0, 50%, 0) scale(0.6);
  transform: rotateX(90deg) translate3d(0, 50%, 0) scale(0.6);
  -webkit-transform-origin: 50% 0;
  -ms-transform-origin: 50% 0;
  transform-origin: 50% 0;
}
.imghvr-fold-down:hover img {
  -webkit-transform: rotateX(-90deg) scale(0.6) translateY(-50%);
  transform: rotateX(-90deg) scale(0.6) translateY(-50%);
}
.imghvr-fold-left img {
  -webkit-transform-origin: 0 50%;
  -ms-transform-origin: 0 50%;
  transform-origin: 0 50%;
}
.imghvr-fold-left figcaption {
  -webkit-transform: rotateY(90deg) translate3d(-50%, 0, 0) scale(0.6);
  transform: rotateY(90deg) translate3d(-50%, 0, 0) scale(0.6);
  -webkit-transform-origin: 100% 50%;
  -ms-transform-origin: 100% 50%;
  transform-origin: 100% 50%;
}
.imghvr-fold-left:hover img {
  -webkit-transform: rotateY(-90deg) scale(0.6) translateX(50%);
  transform: rotateY(-90deg) scale(0.6) translateX(50%);
}
.imghvr-fold-right {
  -webkit-perspective: 50em;
  perspective: 50em;
}
.imghvr-fold-right img {
  -webkit-transform-origin: 100% 50%;
  -ms-transform-origin: 100% 50%;
  transform-origin: 100% 50%;
}
.imghvr-fold-right figcaption {
  -webkit-transform: rotateY(-90deg) translate3d(50%, 0, 0) scale(0.6);
  transform: rotateY(-90deg) translate3d(50%, 0, 0) scale(0.6);
  -webkit-transform-origin: 0 50%;
  -ms-transform-origin: 0 50%;
  transform-origin: 0 50%;
}
.imghvr-fold-right:hover img {
  -webkit-transform: rotateY(90deg) scale(0.6) translateX(-50%);
  transform: rotateY(90deg) scale(0.6) translateX(-50%);
}
.imghvr-zoom-in figcaption {
  opacity: 0;
  -webkit-transform: scale(0.5);
  transform: scale(0.5);
}
.imghvr-zoom-in:hover figcaption {
  -webkit-transform: scale(1);
  transform: scale(1);
  opacity: 1;
}
[class*=" imghvr-zoom-out"] figcaption,
[class^="imghvr-zoom-out"] figcaption {
  -webkit-transform: scale(0.5);
  transform: scale(0.5);
  -webkit-transform-origin: 50% 50%;
  -ms-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  opacity: 0;
}
[class*=" imghvr-zoom-out"].hover figcaption,
[class*=" imghvr-zoom-out"]:hover figcaption,
[class^="imghvr-zoom-out"].hover figcaption,
[class^="imghvr-zoom-out"]:hover figcaption {
  -webkit-transform: scale(1);
  transform: scale(1);
  opacity: 1;
  -webkit-transition-delay: 0.3s;
  transition-delay: 0.3s;
}
.imghvr-zoom-out:hover img {
  -webkit-transform: scale(0.5);
  transform: scale(0.5);
  opacity: 0;
}
.imghvr-zoom-out-up.hover img,
.imghvr-zoom-out-up:hover img {
  -webkit-animation: imghvr-zoom-out-up 0.4s linear;
  animation: imghvr-zoom-out-up 0.4s linear;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}
@-webkit-keyframes imghvr-zoom-out-up {
  50% {
    -webkit-transform: scale(0.8) translateY(0);
    transform: scale(0.8) translateY(0);
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(0.8) translateY(-150%);
    transform: scale(0.8) translateY(-150%);
    opacity: 0.5;
  }
}
@keyframes imghvr-zoom-out-up {
  50% {
    -webkit-transform: scale(0.8) translateY(0);
    transform: scale(0.8) translateY(0);
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(0.8) translateY(-150%);
    transform: scale(0.8) translateY(-150%);
    opacity: 0.5;
  }
}
.imghvr-zoom-out-down.hover img,
.imghvr-zoom-out-down:hover img {
  -webkit-animation: imghvr-zoom-out-down 0.4s linear;
  animation: imghvr-zoom-out-down 0.4s linear;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}
@-webkit-keyframes imghvr-zoom-out-down {
  50% {
    -webkit-transform: scale(0.8) translateY(0);
    transform: scale(0.8) translateY(0);
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(0.8) translateY(150%);
    transform: scale(0.8) translateY(150%);
    opacity: 0.5;
  }
}
@keyframes imghvr-zoom-out-down {
  50% {
    -webkit-transform: scale(0.8) translateY(0);
    transform: scale(0.8) translateY(0);
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(0.8) translateY(150%);
    transform: scale(0.8) translateY(150%);
    opacity: 0.5;
  }
}
.imghvr-zoom-out-left.hover img,
.imghvr-zoom-out-left:hover img {
  -webkit-animation: imghvr-zoom-out-left 0.4s linear;
  animation: imghvr-zoom-out-left 0.4s linear;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}
@-webkit-keyframes imghvr-zoom-out-left {
  50% {
    -webkit-transform: scale(0.8) translateX(0);
    transform: scale(0.8) translateX(0);
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(0.8) translateX(-150%);
    transform: scale(0.8) translateX(-150%);
    opacity: 0.5;
  }
}
@keyframes imghvr-zoom-out-left {
  50% {
    -webkit-transform: scale(0.8) translateX(0);
    transform: scale(0.8) translateX(0);
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(0.8) translateX(-150%);
    transform: scale(0.8) translateX(-150%);
    opacity: 0.5;
  }
}
.imghvr-zoom-out-right.hover img,
.imghvr-zoom-out-right:hover img {
  -webkit-animation: imghvr-zoom-out-right 0.4s linear;
  animation: imghvr-zoom-out-right 0.4s linear;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}
@-webkit-keyframes imghvr-zoom-out-right {
  50% {
    -webkit-transform: scale(0.8) translateX(0);
    transform: scale(0.8) translateX(0);
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(0.8) translateX(150%);
    transform: scale(0.8) translateX(150%);
    opacity: 0.5;
  }
}
@keyframes imghvr-zoom-out-right {
  50% {
    -webkit-transform: scale(0.8) translateX(0);
    transform: scale(0.8) translateX(0);
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(0.8) translateX(150%);
    transform: scale(0.8) translateX(150%);
    opacity: 0.5;
  }
}
.imghvr-zoom-out-flip-horiz {
  -webkit-perspective: 50em;
  perspective: 50em;
}
.imghvr-zoom-out-flip-horiz figcaption {
  opacity: 0;
  -webkit-transform: rotateX(90deg) translateY(-100%) scale(0.5);
  transform: rotateX(90deg) translateY(-100%) scale(0.5);
}
.imghvr-zoom-out-flip-horiz.hover img,
.imghvr-zoom-out-flip-horiz:hover img {
  -webkit-transform: rotateX(-100deg) translateY(50%) scale(0.5);
  transform: rotateX(-100deg) translateY(50%) scale(0.5);
  opacity: 0;
  -webkit-transition-delay: 0;
  transition-delay: 0;
}
.imghvr-zoom-out-flip-horiz.hover figcaption,
.imghvr-zoom-out-flip-horiz:hover figcaption {
  -webkit-transform: rotateX(0) translateY(0) scale(1);
  transform: rotateX(0) translateY(0) scale(1);
  opacity: 1;
  -webkit-transition-delay: 0.3s;
  transition-delay: 0.3s;
}
.imghvr-zoom-out-flip-vert {
  -webkit-perspective: 50em;
  perspective: 50em;
}
.imghvr-zoom-out-flip-vert figcaption {
  opacity: 0;
  -webkit-transform: rotateY(90deg) translate(50%, 0) scale(0.5);
  transform: rotateY(90deg) translate(50%, 0) scale(0.5);
}
.imghvr-zoom-out-flip-vert.hover img,
.imghvr-zoom-out-flip-vert:hover img {
  -webkit-transform: rotateY(-100deg) translateX(50%) scale(0.5);
  transform: rotateY(-100deg) translateX(50%) scale(0.5);
  opacity: 0;
  -webkit-transition-delay: 0;
  transition-delay: 0;
}
.imghvr-zoom-out-flip-vert.hover figcaption,
.imghvr-zoom-out-flip-vert:hover figcaption {
  -webkit-transform: rotateY(0) translate(0, 0) scale(1);
  transform: rotateY(0) translate(0, 0) scale(1);
  opacity: 1;
  -webkit-transition-delay: 0.3s;
  transition-delay: 0.3s;
}
.imghvr-blur figcaption {
  opacity: 0;
}
.imghvr-blur:hover img {
  -webkit-filter: blur(30px);
  filter: blur(30px);
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
  opacity: 0;
}
.imghvr-blur:hover figcaption {
  opacity: 1;
  -webkit-transition-delay: 0.2s;
  transition-delay: 0.2s;
}
[class*=" imghvr-blocks"] figcaption:after,
[class*=" imghvr-blocks"] figcaption:before,
[class*=" imghvr-blocks"]:after,
[class*=" imghvr-blocks"]:before,
[class^="imghvr-blocks"] figcaption:after,
[class^="imghvr-blocks"] figcaption:before,
[class^="imghvr-blocks"]:after,
[class^="imghvr-blocks"]:before {
  background-color: #fff;
  opacity: 0;
  position: absolute;
  content: "";
}
[class*=" imghvr-blocks"]:after,
[class*=" imghvr-blocks"]:before,
[class^="imghvr-blocks"]:after,
[class^="imghvr-blocks"]:before {
  z-index: 1;
}
[class*=" imghvr-blocks"]:before,
[class^="imghvr-blocks"]:before {
  top: 15px;
  right: 15px;
  bottom: 50%;
  left: 50%;
}
[class*=" imghvr-blocks"]:after,
[class^="imghvr-blocks"]:after {
  bottom: 15px;
  left: 15px;
  top: 50%;
  right: 50%;
}
[class*=" imghvr-blocks"] figcaption,
[class^="imghvr-blocks"] figcaption {
  z-index: 3;
  background-color: transparent;
  border-width: 0;
}
[class*=" imghvr-blocks"] figcaption *,
[class^="imghvr-blocks"] figcaption * {
  opacity: 0;
}
[class*=" imghvr-blocks"] figcaption:after,
[class*=" imghvr-blocks"] figcaption:before,
[class^="imghvr-blocks"] figcaption:after,
[class^="imghvr-blocks"] figcaption:before {
  height: 50%;
  width: 50%;
  z-index: -1;
}
[class*=" imghvr-blocks"] figcaption:before,
[class^="imghvr-blocks"] figcaption:before {
  top: 0;
  left: 0;
}
[class*=" imghvr-blocks"] figcaption:after,
[class^="imghvr-blocks"] figcaption:after {
  bottom: 0;
  right: 0;
}
[class*=" imghvr-blocks"] a,
[class^="imghvr-blocks"] a {
  z-index: 3;
}
[class*=" imghvr-blocks"]:hover figcaption:after,
[class*=" imghvr-blocks"]:hover figcaption:before,
[class*=" imghvr-blocks"]:hover:after,
[class*=" imghvr-blocks"]:hover:before,
[class^="imghvr-blocks"]:hover figcaption:after,
[class^="imghvr-blocks"]:hover figcaption:before,
[class^="imghvr-blocks"]:hover:after,
[class^="imghvr-blocks"]:hover:before {
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
  opacity: 0.9;
}
[class*=" imghvr-blocks"]:hover figcaption *,
[class^="imghvr-blocks"]:hover figcaption * {
  opacity: 1;
  -webkit-transition-delay: 0.25s;
  transition-delay: 0.25s;
}
.imghvr-blocks-rotate-left:before {
  -webkit-transform: translateY(100%);
  transform: translateY(100%);
}
.imghvr-blocks-rotate-left:after {
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
}
.imghvr-blocks-rotate-left figcaption:before {
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}
.imghvr-blocks-rotate-left figcaption:after,
.imghvr-blocks-rotate-right:before {
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}
.imghvr-blocks-rotate-right:after {
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}
.imghvr-blocks-rotate-right figcaption:before {
  -webkit-transform: translateY(100%);
  transform: translateY(100%);
}
.imghvr-blocks-rotate-in-left:before,
.imghvr-blocks-rotate-right figcaption:after {
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
}
.imghvr-blocks-rotate-in-left:after {
  -webkit-transform: translateY(100%);
  transform: translateY(100%);
}
.imghvr-blocks-rotate-in-left figcaption:before {
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}
.imghvr-blocks-rotate-in-left figcaption:after,
.imghvr-blocks-rotate-in-right:before {
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}
.imghvr-blocks-rotate-in-right:after {
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}
.imghvr-blocks-rotate-in-right figcaption:before {
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
}
.imghvr-blocks-rotate-in-right figcaption:after {
  -webkit-transform: translateY(100%);
  transform: translateY(100%);
}
.imghvr-blocks-in:before {
  -webkit-transform: translate(100%, -100%);
  transform: translate(100%, -100%);
}
.imghvr-blocks-in:after {
  -webkit-transform: translate(-100%, 100%);
  transform: translate(-100%, 100%);
}
.imghvr-blocks-in figcaption:before {
  -webkit-transform: translate(-100%, -100%);
  transform: translate(-100%, -100%);
}
.imghvr-blocks-in figcaption:after {
  -webkit-transform: translate(100%, 100%);
  transform: translate(100%, 100%);
}
.imghvr-blocks-out:before {
  -webkit-transform: translate(-100%, 100%);
  transform: translate(-100%, 100%);
}
.imghvr-blocks-out:after {
  -webkit-transform: translate(100%, -100%);
  transform: translate(100%, -100%);
}
.imghvr-blocks-out figcaption:before {
  -webkit-transform: translate(100%, 100%);
  transform: translate(100%, 100%);
}
.imghvr-blocks-out figcaption:after {
  -webkit-transform: translate(-100%, -100%);
  transform: translate(-100%, -100%);
}
[class*=" imghvr-book-open-"] figcaption,
[class^="imghvr-book-open-"] figcaption {
  background-color: transparent;
  border: none;
  -webkit-perspective: 50em;
  perspective: 50em;
}
[class*=" imghvr-book-open-"] figcaption *,
[class^="imghvr-book-open-"] figcaption * {
  opacity: 0;
}
[class*=" imghvr-book-open-"] figcaption:after,
[class*=" imghvr-book-open-"] figcaption:before,
[class^="imghvr-book-open-"] figcaption:after,
[class^="imghvr-book-open-"] figcaption:before {
  z-index: -1;
  background-color: #999;
  content: "";
  position: absolute;
  opacity: 0;
  -webkit-transition: all 0.45s ease;
  transition: all 0.45s ease;
}
[class*=" imghvr-book-open-"]:hover figcaption,
[class^="imghvr-book-open-"]:hover figcaption {
  opacity: 1;
}
[class*=" imghvr-book-open-"]:hover figcaption *,
[class^="imghvr-book-open-"]:hover figcaption * {
  opacity: 1;
  -webkit-transition-delay: 0.2s;
  transition-delay: 0.2s;
}
[class*=" imghvr-book-open-"]:hover figcaption:after,
[class*=" imghvr-book-open-"]:hover figcaption:before,
[class^="imghvr-book-open-"]:hover figcaption:after,
[class^="imghvr-book-open-"]:hover figcaption:before {
  opacity: 1;
  background-color: #fff;
  -webkit-transform: rotateY(0);
  transform: rotateY(0);
}
.imghvr-book-open-horiz figcaption:after,
.imghvr-book-open-horiz figcaption:before {
  height: 100%;
  width: 50%;
  top: 0;
}
.imghvr-book-open-horiz figcaption:before {
  background-image: -webkit-linear-gradient(
    left,
    transparent 60%,
    rgba(0, 0, 0, 0.1) 99%,
    rgba(0, 0, 0, 0.15) 100%
  );
  background-image: linear-gradient(
    to right,
    transparent 60%,
    rgba(0, 0, 0, 0.1) 99%,
    rgba(0, 0, 0, 0.15) 100%
  );
  left: 0;
  -webkit-transform: rotateY(90deg);
  transform: rotateY(90deg);
  -webkit-transform-origin: 100% 50%;
  -ms-transform-origin: 100% 50%;
  transform-origin: 100% 50%;
}
.imghvr-book-open-horiz figcaption:after {
  background-image: -webkit-linear-gradient(
    right,
    transparent 60%,
    rgba(0, 0, 0, 0.12) 99%,
    rgba(0, 0, 0, 0.15) 100%
  );
  background-image: linear-gradient(
    to left,
    transparent 60%,
    rgba(0, 0, 0, 0.12) 99%,
    rgba(0, 0, 0, 0.15) 100%
  );
  right: 0;
  -webkit-transform: rotateY(-90deg);
  transform: rotateY(-90deg);
  -webkit-transform-origin: 0 50%;
  -ms-transform-origin: 0 50%;
  transform-origin: 0 50%;
}
.imghvr-book-open-vert figcaption:after,
.imghvr-book-open-vert figcaption:before {
  height: 50%;
  width: 100%;
  left: 0;
}
.imghvr-book-open-vert figcaption:before {
  background-image: -webkit-linear-gradient(
    top,
    transparent 60%,
    rgba(0, 0, 0, 0.1) 99%,
    rgba(0, 0, 0, 0.15) 100%
  );
  background-image: linear-gradient(
    to bottom,
    transparent 60%,
    rgba(0, 0, 0, 0.1) 99%,
    rgba(0, 0, 0, 0.15) 100%
  );
  top: 0;
  -webkit-transform: rotateX(-90deg);
  transform: rotateX(-90deg);
  -webkit-transform-origin: 50% 100%;
  -ms-transform-origin: 50% 100%;
  transform-origin: 50% 100%;
}
.imghvr-book-open-vert figcaption:after {
  background-image: -webkit-linear-gradient(
    bottom,
    transparent 60%,
    rgba(0, 0, 0, 0.13) 99%,
    rgba(0, 0, 0, 0.15) 100%
  );
  background-image: linear-gradient(
    to top,
    transparent 60%,
    rgba(0, 0, 0, 0.13) 99%,
    rgba(0, 0, 0, 0.15) 100%
  );
  bottom: 0;
  -webkit-transform: rotateX(90deg);
  transform: rotateX(90deg);
  -webkit-transform-origin: 50% 0;
  -ms-transform-origin: 50% 0;
  transform-origin: 50% 0;
}
[class*=" imghvr-scale-"]:before,
[class^="imghvr-scale-"]:before {
  background-color: inherit;
  top: 15px;
  bottom: 15px;
  left: 15px;
  right: 15px;
  position: absolute;
  content: "";
}
[class*=" imghvr-scale-"] figcaption,
[class^="imghvr-scale-"] figcaption {
  opacity: 0;
}
[class*=" imghvr-scale-"]:hover:before,
[class^="imghvr-scale-"]:hover:before {
  top: 15px;
  bottom: 15px;
  left: 15px;
  right: 15px;
  opacity: 0.9;
}
[class*=" imghvr-scale-"]:hover figcaption,
[class^="imghvr-scale-"]:hover figcaption {
  opacity: 1;
  -webkit-transition-delay: 0.25s;
  transition-delay: 0.25s;
}
.imghvr-scale-top-left:before {
  bottom: 100%;
  right: 100%;
}
.imghvr-scale-top-right:before {
  bottom: 100%;
  left: 100%;
}
.imghvr-scale-bottom-left:before {
  top: 100%;
  right: 100%;
}
.imghvr-scale-bottom-right:before {
  top: 100%;
  left: 100%;
}
[class*=" imghvr-fall-away-"],
[class^="imghvr-fall-away-"] {
  -webkit-perspective: 50em;
  perspective: 50em;
}
[class*=" imghvr-fall-away-"] img,
[class^="imghvr-fall-away-"] img {
  -webkit-transform-origin: 50% 50%;
  -ms-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  -webkit-transition: all 0.45s ease-in;
  transition: all 0.45s ease-in;
}
[class*=" imghvr-fall-away-"] figcaption,
[class*=" imghvr-fall-away-"]:hover img,
[class^="imghvr-fall-away-"] figcaption,
[class^="imghvr-fall-away-"]:hover img {
  opacity: 0;
}
[class*=" imghvr-fall-away-"]:hover figcaption,
[class^="imghvr-fall-away-"]:hover figcaption {
  opacity: 1;
  -webkit-transition-delay: 0.45s;
  transition-delay: 0.45s;
}
.imghvr-fall-away-horiz:hover img {
  -webkit-transform: rotateX(-360deg) scale(0.2);
  transform: rotateX(-360deg) scale(0.2);
}
.imghvr-fall-away-horiz:hover figcaption {
  -webkit-transform: rotateX(0);
  transform: rotateX(0);
}
.imghvr-fall-away-vert:hover img {
  -webkit-transform: rotateY(-360deg) scale(0.2);
  transform: rotateY(-360deg) scale(0.2);
}
.imghvr-fall-away-vert:hover figcaption {
  -webkit-transform: rotateX(0);
  transform: rotateX(0);
}
