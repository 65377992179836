.header {
  font-size: 30px;
  color: #00a14b;
  line-height: 27px;
  margin: 20px 0px 5px 0px;
}

.title {
  font-weight: 500;
  font-size: 18px;
  margin: 15px 0px 10px 0px;
}

.subtitle {
  color: #5f6377;
  font-weight: 400;
  font-size: 18px;
  margin: 10px 0px;
}

.input-box {
  background-color: #f3f5f6;
  color: #4e5a6c;
  font-size: 12px;
  padding: 8px 12px;
  border: none;
  height: 40px;
  outline: none;
  width: 70%;
  border-radius: 5px;
}

.cancel-button {
  padding: 10px 15px;
  color: black;
  background-color: transparent;
  border-radius: 5px;
  border: 1px solid #00a14b;
  line-height: 29px;
  font-weight: 500;
  font-size: 20px;
}

.confirm-button {
  padding: 10px 15px;
  color: #fff;
  background-color: #00a14b;
  border-radius: 8px;
  border: transparent;
  line-height: 29px;
  font-weight: 500;
  font-size: 20px;
}